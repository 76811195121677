document.addEventListener("turbo:load", function() {
  document.querySelector('#products.index') && products.index()
  document.querySelector('#products.edit') && products.edit()
});

var products = {
  index() {
    if ($(".products-search").length > 0) {
      $("#search_input").focus();
  
      $("#search_input").keyup(function(event) {
        if (check_search_inputs(event)) {
          return;
        }
        delay((function() {
          perform_search();
          return false;
        }), 1500);
        return false;
      });
  
      $(".search-field").change(() => perform_search());
  
      var delay = (function() {
        let timer = 0;
        return function(callback, ms) {
          clearTimeout(timer);
          timer = setTimeout(callback, ms);
        };
      })();
    }
  
    var check_search_inputs = function(event) {
      if (event.keyCode === 16) {
        return true;
      } else if (event.keyCode === 17) {
        return true;
      } else if (event.keyCode === 18) {
        return true;
      } else if (event.keyCode === 91) {
        return true;
      } else if (event.keyCode === 93) {
        return true;
      } else if (event.keyCode === 37) {
        return true;
      } else if (event.keyCode === 38) {
        return true;
      } else if (event.keyCode === 39) {
        return true;
      } else if (event.keyCode === 40) {
        return true;
      } else {
        return false;
      }
    };
    
    var perform_search = function() {
      $("#search_input").parent().addClass('loading')
      return $.ajax({
        url: "/products",
        dataType: "script",
        data: collect_search(),
        complete() {
          return $("#search_input").parent().removeClass('loading')
        }
      });
    };
  
    var collect_search = function() {
      const search_params = {};
      $('.search-field').each(function(index, element) {
        if ($(element).hasClass('dropdown')) {
          element = $(element).children().first();
        }
        const id = $(element).attr("id");
        const value = $(element).val();
        return search_params[id] = value;
      });
      return search_params;
    };

    perform_search()
  },

  edit() {
    $(".multi_select").multiSelect();

    if ($('#product_kind').val() !== "menu") {
      $('#switch_product').button('toggle');
      $('#product_kind').val("product");
      $('#product_form').show();
      $('#menu_form').hide();
    } else {
      $("#switch_menu").button('toggle');
      $("#product_form").hide();
      $("#menu_form").show();
    }
  
    $("#switch_product").on("click", function(e) {
      $('#product_product_kind').val("product");
      $('#product_namefield').val($('#menu_namefield').val());
      $('#product_name2field').val($('#menu_name2field').val());
      $('#product_pricefield').val($('#menu_pricefield').val());
      $('#product_subfield').val($('#menu_subfield').val());
      return $("#menu_form").hide("fast", () => $("#product_form").show("fast"));
    });
      
    $("#switch_menu").on("click", function(e) {
      $('#menu_product_kind').val("menu");
      $('#menu_namefield').val($('#product_namefield').val());
      $('#menu_name2field').val($('#product_name2field').val());
      $('#menu_pricefield').val($('#product_pricefield').val());
      $('#menu_subfield').val($('#product_subfield').val());
      return $("#product_form").hide("fast", () => $("#menu_form").show("fast"));
    });
      
    $('#rating').raty({
      scoreName: "product[restaurant_rating]",
      space: true,
      cancel: true,
      hints: [null, null, null, null, null],
      starOff: $(".star-off-path").data("path"),
      starOn: $(".star-on-path").data("path"),
      cancelOff: $(".cancel-off-path").data("path"),
      cancelOn : $(".cancel-on-path").data("path"),
      score() {
        return $(this).attr("data-score");
      }
    });
  }
};

window.products = products;
