document.addEventListener("turbo:load", function() {
  //$('.best_in_place').best_in_place();

  $(".sortable").sortable({
    connectWith: ['.sortable', 'sortable-sub'],
    axis:'y',
    scroll: false,
    update() {
      const serialize_var = $(this).sortable('serialize');
      console.log(serialize_var);
      return $.post($(this).data('update-url'), serialize_var);
    }
  });

  $(".sortable-sub").sortable({
    connectWith: ['.sortable-sub'],
    axis:'y',
    dropOnEmpty: true,
    cursor: "move",
    delay: 150,
    cursorAt: { left: 5, top: 5 },
    grid: [ 20, 10 ],
    forcePlaceholderSize: true,
    tolerance: 'pointer',
    scroll: false,
    handle: ".drag-handle",
    update() {
      console.log($(this));
      console.log($(this).sortable('serialize'));
      let serialize_var = $(this).sortable('serialize');
      serialize_var += "&maincategory[]=" + $(this).parents().eq(3).attr("id");
      return $.post($(this).data('update-url'), serialize_var);
    }
  });

  $(".sortable-product").sortable({
    connectWith: ['.sortable-product'],
    axis:'y',
    scroll: false,
    handle: ".drag-handle",
    update() {
      console.log($(this).sortable('serialize'));
      const serialize_var = $(this).sortable('serialize');
      return $.post($(this).data('update-url'), serialize_var);
    }
  });

  $('.collapse').on('hide.bs.collapse', function(event) {
    $(this).parent().find("i.glyphicon.glyphicon-chevron-up").removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
  });
    
  $('.collapse').on('show.bs.collapse', function(event) {
    $(this).parent().find("i.glyphicon.glyphicon-chevron-down").removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up')
  });

  $(".accordion-heading").hover((function() {
    return $(this).children().children().next('.glyphicon-move').show();
  }), function() {
    return $(this).children().children().next('.glyphicon-move').hide();
  });
  
  $(".modal-label").hover((function() {
    return $(this).children().next('.glyphicon-edit').show();
  }), function() {
    return $(this).children().next('.glyphicon-edit').hide();
  });

  $(".multi_select").multiSelect();

  $(".product-name").autocomplete({
    minLength: 3,
    source: "/products.json",
    select(event, ui) {
      $(this).val(ui.item.name)
      $(".subcategory_product_name").val(ui.item.name);
      $(".subcategory_product_id").val(ui.item.id);
      return false;
    }
  });

  $.ui.autocomplete.prototype._renderItem = function(ul, item) {
    if (item.order_number === "") {
      return $( "<li>" ).append("<a>" + item.name + " (Price: " + parseFloat(item.price).toFixed(2) + ")</a>").appendTo(ul);
    } else {
      return $( "<li>" ).append("<a>" + item.order_number + " - " + item.name + " (Price: " + parseFloat(item.price).toFixed(2) + ")</a>").appendTo(ul);
    }
  };
});
  