document.addEventListener("turbo:load", function() {
  document.querySelector('#producers.index') && producers.index()
  document.querySelector('#producers.edit') && producers.edit()  
});

var producers = {
  index() {
    if ($(".producers-search").length > 0) {
      $("#search_input").focus();
  
      $("#search_input").keyup(function(event) {
        if (check_search_inputs(event)) {
          return;
        }
        delay((function() {
          perform_search();
          return false;
        }), 1500);
        return false;
      });
  
      $(".search-field").change(() => perform_search());
  
      var delay = (function() {
        let timer = 0;
        return function(callback, ms) {
          clearTimeout(timer);
          timer = setTimeout(callback, ms);
        };
      })();
    }
  
    var check_search_inputs = function(event) {
      if (event.keyCode === 16) {
        return true;
      } else if (event.keyCode === 17) {
        return true;
      } else if (event.keyCode === 18) {
        return true;
      } else if (event.keyCode === 91) {
        return true;
      } else if (event.keyCode === 93) {
        return true;
      } else if (event.keyCode === 37) {
        return true;
      } else if (event.keyCode === 38) {
        return true;
      } else if (event.keyCode === 39) {
        return true;
      } else if (event.keyCode === 40) {
        return true;
      } else {
        return false;
      }
    };
    
    var perform_search = function() {
      $("#search_input").parent().addClass('loading')
      return $.ajax({
        url: "/producers",
        dataType: "script",
        data: collect_search(),
        complete() {
          return $("#search_input").parent().removeClass('loading')
        }
      });
    };
  
    var collect_search = function() {
      const search_params = {};
      $('.search-field').each(function(index, element) {
        if ($(element).hasClass('dropdown')) {
          element = $(element).children().first();
        }
        const id = $(element).attr("id");
        const value = $(element).val();
        return search_params[id] = value;
      });
      return search_params;
    };

    perform_search()
  },

  edit() {

  }
};

window.producers = producers;
