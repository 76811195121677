document.addEventListener("turbo:load", function() {
  document.querySelector('#themes.edit') && themes.edit()
});

var themes = {
  edit() {
    $('.colorpicker').colorpicker()
    $(".colorpicker").each(function(index) {
      if ($(this).val() !== "") {
        $(this).css('background-color', $(this).val())
        $(this).css("color", themes.invertColor($(this).val()))
      }

      $(this).change(function() {
        $(this).css('background-color', $(this).val())
        $(this).css("color", themes.invertColor($(this).val()))
      })

      $(this).colorpicker().on("changeColor", function(ev) {
        $(this).css('background-color', ev.color.toHex())
        $(this).css("color", themes.invertColor(ev.color.toHex()))
      })
    });
  },

  invertColor(hexTripletColor) {
    let color = hexTripletColor
    color = color.substring(1) // remove #
    color = parseInt(color, 16) // convert to integer
    color = 0xFFFFFF ^ color // invert three bytes
    color = color.toString(16) // convert to hex
    color = ("000000" + color).slice(-6) // pad with leading zeros
    color = "#" + color // prepend #
    return color
  },
}

window.themes = themes
