document.addEventListener("turbo:load", function() {

  $('.message .close').on('click', function() {
    $(this).closest('.message').transition('fade')
  })
  
  $('.field_with_errors').each(function() {
    $(this).parent().append($(this).children().detach()).addClass('error')
    return $(this).detach()
  })

  if ($(".dashboard").length > 0) {

    if ($('#registration_chart_users').length > 0) {
      Morris.Area({
        element: "registration_chart_users",
        data: $('#registration_chart_users').data('registrations'),
        xkey: "x",
        ykeys: ["count"],
        labels: [$('#registration_chart_users').data('title')],
        lineColors: ["#46a546"],
        fillOpacity: 0.6,
        resize: true
      });
    }
      
    if ($('#registration_chart_devices').length > 0) {
      Morris.Area({
        element: "registration_chart_devices",
        data: $('#registration_chart_devices').data('registrations'),
        xkey: "x",
        ykeys: ["count"],
        labels: [$('#registration_chart_devices').data('title')],
        lineColors: ["#f89406"],
        fillOpacity: 0.6,
        resize: true
      });
    }
      
    if ($('#feedback_chart').length > 0) {
      return Morris.Area({
        element: "feedback_chart",
        data: $('#feedback_chart').data('feedbacks'),
        xkey: "x",
        ykeys: ["count"],
        labels: [$('#feedback_chart').data('title')],
        lineColors: ["#049cdb"],
        fillOpacity: 0.6,
        resize: true
      });
    }

  }
});
